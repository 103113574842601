import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}

export const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
        filterSection: (state, action) => {
      state.value = action.payload
    },
  },
})

export const {filterSection } = FilterSlice.actions

export default FilterSlice.reducer