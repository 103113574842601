import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { store } from './App/Store'
import { Provider } from 'react-redux'

import reportWebVitals from './reportWebVitals';
import 'admin-lte/dist/css/adminlte.css';
import 'admin-lte/dist/js/adminlte.js';
import "admin-lte/plugins/fontawesome-free/css/all.css";
import "admin-lte/plugins/bootstrap/js/bootstrap.js";
import "admin-lte/plugins/jquery/jquery.min.js";
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.js";
import 'react-select2-wrapper/css/select2.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { user, currency,toastMsg,toastValidationMsg,Img,ucword,dFormat } from './Helper/Helper.js';

window.user = user;
window.currency = currency;
window.toastMsg = toastMsg;
window.toastValidationMsg = toastValidationMsg;
window.Img = Img;
window.ucword = ucword;
window.dFormat = dFormat;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    <ToastContainer />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
