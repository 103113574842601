import React from "react";
import { user, permission } from "../Helper/Helper.js";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  const userData = user();
  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link
          to="/dashboard"
          className="brand-link text-center"
          style={{ padding: "0px !importanct" }}
        >
          <img
            src="/parmukh.png"
            className=""
            style={{ height: "56px", padding: "3px" }}
          />
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item ">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>Dashboard</p>
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-chart-pie"></i>
                  <p>
                    Charts
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="pages/charts/chartjs.html" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>ChartJS</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="pages/charts/flot.html" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Flot</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="pages/charts/inline.html" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Inline</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="pages/charts/uplot.html" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>uPlot</p>
                    </a>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item">
                <NavLink to="/menu" className="nav-link">
                  <i className="nav-icon fas fa-utensils"></i>
                  <p className="text">Menu</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/menu-stock" className="nav-link">
                  <i className="nav-icon fas fa-cubes"></i>
                  <p>Menu Stock</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/stock-report" className="nav-link">
                  <i class="nav-icon fas fa-chart-line"></i>
                  <p>Stock Report</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/order" className="nav-link">
                  <i class="nav-icon fas fa-cart-arrow-down"></i>
                  <p>Order</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/order-history" className="nav-link">
                  <i class="nav-icon fas fa-file-contract"></i>
                  <p>Order History</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
