import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
function Auth(props) {

  const navigate = useNavigate();
//   const userData = JSON.parse(sessionStorage.getItem("userData"))
  let Component = props.component;
  console.log(props);
  useEffect(() => {
    if (!sessionStorage.getItem("userData")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Component />
    </>
  );
}
export default Auth;
