import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterSection } from "../App/Features/Filter/FilterSlice";

const FilterBtn = () => {
  const [filterFlag, setFilterFlag] = useState(false);
  const dispatch = useDispatch();
  //for filter show
  const filter = (flag) => {
    let newF = flag ? false : true;
    setFilterFlag(newF);
    dispatch(filterSection(newF));
  };
  return (
    <>
      <a
        href="javascript:void(0);"
        className="btn btn-sm btn-success"
        onClick={(e) => {
          filter(filterFlag);
        }}
      >
        {!filterFlag && (
          <>
            <i className="fas fa-filter"></i>&nbsp;Filter
          </>
        )}
        {filterFlag && (
          <>
            <i className="far fa-times-circle"></i>&nbsp;Close
          </>
        )}
      </a>
    </>
  );
};

export default FilterBtn;
