// export function permission(roles) {
//     const userData = JSON.parse(sessionStorage.getItem("user"));
//     let role = userData !== null ? userData.user.role : 0;
  
//     if (roles.indexOf(role) >= 0) {
//       return true;
//     } else {
//       return false;
//     }
//   }
import { ToastContainer, toast } from "react-toastify";
  
  export function user(){
    const userVal = JSON.parse(sessionStorage.getItem('userData'));
    return userVal?.user;
  }

  export function currency(amount) {
    return `₹ ${amount}`;
  }
  
  export function toastMsg(msg,status){
    if(status){
    toast.success(msg, {
      theme: "colored",
    });
  }else if(!status){
    toast.error(msg, {
      theme: "colored",
    });
  }
  }
// Import necessary modules if needed

export function toastValidationMsg(message) {
  let msg = '';
  if (message) {
    const validationMessages = message;
    Object.keys(validationMessages).forEach(key => {
      const messages = validationMessages[key];
      messages.forEach((val, index) => {
        msg += `<p>${val}</p>`;
      });
    });
    toast.error(() => (
      <div dangerouslySetInnerHTML={{ __html: msg }} />
    ),{ theme: "colored", icon: false});
  }
}

export function ucword(str) {
  if (typeof str !== 'string' || str.length === 0) {
      return str; // Return the string as is if it's empty or not a string
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function dFormat(timestamp) {
  // Convert the timestamp to milliseconds
  const date = new Date(timestamp * 1000);
  
  // Define month names
  const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  
  // Extract day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  
  // Return formatted date string
  return `${day} ${month} ${year}`;
}

export function Img(){
  return '/dummyImg.jpg';
}
