import React, { useEffect, useState } from "react";
import http from "../../http";
import { currency,Img } from "../../Helper/Helper";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loaderSection } from "../../App/Features/Loader/TableSlice";
import TableLoader from "../../Components/TableLoader";

export default function Index() {
  const dispatch = useDispatch();
  const tabLoader = useSelector((state) => state.tableLoader.value);
  const [records, setRecords] = useState([]);
  const [menus, setMenus] = useState([]);

  //for show list of data
  useEffect(() => {
    list();
    menuList();
  }, []);

  const list = async () => {
    await http
      .get("menu-list")
      .then((res) => {
        setMenus(res.data.records);
      })
      .catch((error) => {
        // console.log('Auth Error',);
        toast.error(error.response.data.msg, {
          theme: "colored",
        });
      });
  };

  const menuList = async (menuId = "") => {
    let payload = { menu_id: menuId };
    dispatch(loaderSection(true));
    await http
      .get("assign-menu-list", { params: payload })
      .then((res) => {
        setRecords(res.data.records);
        dispatch(loaderSection(false));
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          theme: "colored",
        });
        dispatch(loaderSection(false));
      });
  };

  function changeMenu(menu_id) {
    menuList(menu_id);
  }

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            <div className="card-body p-2">
              <div className="card p-2">
                <div className="row">
                  <div className="col-md-4">
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        changeMenu(e.target.value);
                      }}
                    >
                      <option value="">All Manu</option>
                      {menus &&
                        menus.map((list, index) => (
                          <option value={list.id}>{list.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              {!tabLoader ? (
                records.map((record, index1) => (
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        id="headingOne"
                        style={{ padding: 0 }}
                      >
                        <h5 className="mb-0">
                          <button className="btn btn-link">
                            {record.menu}
                          </button>
                        </h5>
                      </div>

                      <div className="collapse show">
                        <div className="card-body">
                          <div className="row">
                            {record.sub_menu &&
                              record.sub_menu.map((subMenu, index2) => (
                                <div className="col-md-6" id="accordion">
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                      style={{ padding: 0 }}
                                    >
                                      <h5 className="mb-0">
                                        <button className="btn btn-link">
                                          {subMenu.name}
                                        </button>
                                      </h5>
                                    </div>

                                    <div className="collapse show">
                                      <div className="card-body p-0">
                                        <table className="table table-sm">
                                          <thead>
                                            <tr>
                                              <th>Image</th>
                                              <th>Item</th>
                                              <th>Price</th>
                                              <th>Stock</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {subMenu.menu_items &&
                                              subMenu.menu_items.map(
                                                (item, index11) => (
                                                  <tr>
                                                    <td>
                                                      <img className="imgTable" src={item.image??Img()} />
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                      {currency(item.price)}
                                                    </td>
                                                    <td>{item.stock}</td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <TableLoader colspan="9" type="div" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
