import React, { useEffect, useState, useRef } from "react";
import http from "../../http";
import { currency } from "../../Helper/Helper";
import FilterBtn from "../../Components/FilterBtn";
import { filterSection } from "../../App/Features/Filter/FilterSlice";
import { useSelector, useDispatch } from "react-redux";
import { loaderSection } from "../../App/Features/Loader/TableSlice";
import TableLoader from "../../Components/TableLoader";

export default function Index() {
  const dispatch = useDispatch();
  const tabLoader = useSelector((state) => state.tableLoader.value);
  const showFilter = useSelector((state) => state.filter.value);
  const [records, setRecord] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const inputRefs = useRef([]);

  //for show list of data
  useEffect(() => {
    menuStockList();
  }, []);
  const menuStockList = async () => {
    dispatch(loaderSection(true));
    await http
      .get("menu-stock")
      .then((res) => {
        setRecord(res.data.records);
        dispatch(loaderSection(false));
      })
      .catch((error) => {
        toastMsg(error, false);
        dispatch(loaderSection(false));
      });
  };

  const handleActionClick = (index) => {
    const inputValue = inputRefs.current[index].value;
    let paylod = { stock_id: index, received_qty: inputValue };

    http
      .post("menu-stock-receive", paylod)
      .then((res) => {
        if (res.data.validation) {
          toastValidationMsg(res.data.validation);
          return false;
        }
        toastMsg(res.data.msg, res.data.status);
        menuStockList();
      })
      .catch((error) => {
        toastMsg(error, false);
      });
  };

  // Handle checkbox state change
  const handleCheckbox = (e) => {
    const { checked } = e.target;
    setCheckAll(checked);
  };

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Menu Stock</h3>
                <div className="card-tools">
                  {/*<a
                    href="javascript:void(0);"
                    className="btn btn-sm btn-success mr-2"
                    id="filter-btn"
                  >
                    Bulk Receiving
                  </a>*/}
                  <FilterBtn />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                {showFilter && <div>Filter</div>}
                <table id="example1" className="table table-hover table-sm">
                  <tr>
                   {/*  <th>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={checkAll}
                        onChange={handleCheckbox}
                      />
                    </th>*/}
                    <th>S.No</th>
                    <th>Menu</th>
                    <th>Sub Menu</th>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Assign Stock</th>
                    <th>Pending Stock</th>
                    <th>Received Stock</th>
                    <th style={{ width: 100 }}>Stock-UP</th>
                    <th>Assign Date</th>
                    <th>Action</th>
                  </tr>
                  {!tabLoader ? (
                    records.length > 0 ? (
                      records.map((record, index) => (
                        <tr>
                         {/*<td>
                            <input
                              type="checkbox"
                              name={`select-${index}`}
                              checked={checkAll}
                              onChange={() => {}}
                            />
                          </td>*/}
                          <td>{++index}</td>
                          <td>{record.menu}</td>
                          <td>{record.sub_menu}</td>
                          <td>{record.menu_item}</td>
                          <td>{currency(record.price)}</td>
                          <td>{record.stock.assign_stock ?? 0}</td>
                          <td>
                            {record.stock.assign_stock -
                              record.stock.received_qty}
                          </td>
                          <td>{record.stock.received_qty ?? 0}</td>
                          <td>
                            <input
                              type="number"
                              defaultValue={
                                record.stock.assign_stock -
                                record.stock.received_qty
                              }
                              className="form-control form-control-sm"
                              ref={(ref) =>
                                (inputRefs.current[record.stock.id] = ref)
                              }
                              // onChange={(e) => handleInputChange(e,record.id)}
                            />
                          </td>
                          <td>{record.stock.assign_date ?? "-"}</td>
                          <td>
                            {record.stock.assign_stock &&
                            record.stock.received_qty !=
                              record.stock.assign_stock ? (
                              <a
                                href="javascript:void(0);"
                                onClick={() =>
                                  handleActionClick(record.stock.id)
                                }
                                className="btn btn-sm btn-success"
                              >
                                <i className="fas fa-check-circle"></i>
                              </a>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={11} className="text-center">
                          Not Found Any Records.
                        </td>
                      </tr>
                    )
                  ) : (
                    <TableLoader colspan="11" type="table" />
                  )}
                </table>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
