// import logo from './logo.svg';
import './App.css';

import RouteFile from './Route/RouteFile';

function App() {
  return (
    <RouteFile />
  );
}

export default App;
