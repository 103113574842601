import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Loader from "../Components/Loader";

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current location is the login page
  const isLoginPage = location.pathname === "/";

  // Don't render header and footer on the login page
  if (isLoginPage) {
    return <div className="hold-transition login-page">{children}</div>;
  }

  // Render header and footer on other pages
  return (
    <div className="wrapper">
      {/* <Loader /> */}
      <Header />
      <Sidebar></Sidebar>
      <div className="content-wrapper">
        <div className="content">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
