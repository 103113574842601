import React, { useState } from "react";
import http from "../../http";
import { combineSlices } from "@reduxjs/toolkit";

const StatusUpdate = ({ id,modifedRecords }) => {
  const [input, setInput] = useState([]);
  const handleInput = (e) => {
    e.persist();
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      order_id: id,
      status: input.status,
      remarks: input.remarks,
    };
    http
      .post("order-status-update", payload)
      .then((res) => {
        if (res.data.validation) {
          toastValidationMsg(res.data.validation);
          return false;
        }
        toastMsg(res.data.msg, res.data.status);
        modifedRecords(id,input.status);
      })
      .catch((error) => {
        toastMsg(error, false);
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Update Status
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-1">
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-8">
                    <label>Select Status</label>
                    <select
                      className="form-control form-control-sm"
                      name="status"
                      id="status"
                      required
                      onChange={handleInput}
                    >
                      <option value="">Select</option>
                      <option value={"pending"}>Pending</option>
                      <option value={"in_progress"}>In Progress</option>
                      <option value={"completed"}>Completed</option>
                    </select>
                  </div>

                  <div className="form-group col-md-12">
                    <label>Remarks</label>
                    <textarea
                      onInput={handleInput}
                      className="form-control"
                      name="remaks"
                      placeholder="Enter Remarks"
                    ></textarea>
                  </div>

                  <div className="form-group col-md-12 text-center">
                    <button type="submit" className="btn btn-sm btn-success">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusUpdate;
