import React, { useEffect, useState, useRef } from "react";
import http from "../../http";
import { currency } from "../../Helper/Helper";
import Filter from "./Filter";
import FilterBtn from "../../Components/FilterBtn";
import { useSelector, useDispatch } from "react-redux";
import { loaderSection } from "../../App/Features/Loader/TableSlice";
import TableLoader from "../../Components/TableLoader";
import Pagination from "../../Components/Pagination";

export default function StockReport() {
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.filter.value);
  const tabLoader = useSelector((state) => state.tableLoader.value);
  const [records, setRecord] = useState([]);
  // const [page,setPage] = useState([]);

  //for show list of data
  useEffect(() => {
    menuStockReport();
  }, []);

  const menuStockReport = async (filters = []) => {
    dispatch(loaderSection(true));
    await http
      .get("menu-stock-report", { params: filters })
      .then((res) => {
        setRecord(res.data.records ?? []);
        if (!res.data.status) toastMsg(res.data.msg, false);
        dispatch(loaderSection(false));
      })
      .catch((error) => {
        console.log("Error", error);
        toastMsg(error, false);
        dispatch(loaderSection(false));
      });
  };

  // Function to receive value from child
  const handleFilter = (value) => {
    menuStockReport(value);
  };

  const handlePageChange = (page, perPage) => {
    menuStockReport({ page: page, perPage: perPage });
  };
  const handleItemsPerPageChange = (perPage) => {
    menuStockReport({ page: 1, perPage: perPage });
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Stock Report</h3>
                <div className="card-tools">
                  <FilterBtn />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                {showFilter && <Filter onValueChange={handleFilter} />}

                <table id="example1" className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Menu</th>
                      <th>Sub Menu</th>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Assign Stock</th>
                      <th>Assign Date</th>
                      <th>Receive Stock</th>
                      <th>Pending Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!tabLoader ? (
                      records.length > 0 ? (
                        records.map((record, index) => (
                          <tr>
                            <td>{++index}</td>
                            <td>{record.menu}</td>
                            <td>{record.sub_menu}</td>
                            <td>{record.menu_item}</td>
                            <td>{currency(record.price)}</td>
                            <td>{record.assign_stock ?? 0}</td>
                            <td>{record.assign_date ?? "-"}</td>
                            <td>{record.received_stock ?? 0}</td>
                            <td>{record.pending_stock ?? 0}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            Not Found Any Records.
                          </td>
                        </tr>
                      )
                    ) : (
                      <TableLoader colspan="9" type="table" />
                    )}
                  </tbody>
                </table>
                {/* <hr className="mb-0"></hr> */}
                {/* <Pagination
                  records={records}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                /> */}
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
