import React, { useState, useEffect } from "react";
import http from "../../http";

const Filter = ({ onValueChange }) => {
  const [input, setInput] = useState([]);
  const [menus, setMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);

  useEffect(() => {
    menuList();
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const changeMenu = (value) => {
    setInput({ ...input, menu_id: value });
    subMenuList(value);
  };

  const setFilter = async () => {
    onValueChange(input);
  };

  const clear = async () => {
    setInput([]);
    onValueChange([]);
  };

  const menuList = async () => {
    await http.get("menu-list").then((res) => {
      setMenus(res.data.records);
    });
  };
  const subMenuList = async (value) => {
    // console.log('erererer',input);
    let payload = { menu_id: value };
    await http.get("sub-menu-list", { params: payload }).then((res) => {
      setSubMenus(res.data.records);
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <div className="form-group">
            <label>Select Menu</label>
            <select
              className="form-control form-control-sm"
              id="menu_id"
              name="menu_id"
              onChange={(e) => changeMenu(e.target.value)}
            >
              <option value="">All Menu</option>
              {menus &&
                menus.map((list, index) => (
                  <option key={index} value={list.id}>
                    {list.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-md-2">
          <div className="form-group">
            <label>Select Sub Menu</label>
            <select
              className="form-control form-control-sm"
              disabled={subMenus ? false : true}
              name="sub_menu_id"
              onChange={handleInput}
            >
              <option value="">All Sub Menu</option>
              {subMenus &&
                subMenus.map((list, index) => (
                  <option key={index} value={list.id}>
                    {list.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-2 mt-4">
          <button
            type="button"
            onClick={setFilter}
            className="btn btn-success btn-sm mr-2"
          >
            <i className="fas fa-search"></i>&nbsp;Search
          </button>
          <a href="javascript:void(0);" onClick={clear} className="btn btn-danger btn-sm">
            <i className="fas fa-eraser"></i>&nbsp;Clear
          </a>
        </div>
      </div>
    </>
  );
};

export default Filter;
