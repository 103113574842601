import React, { useEffect, useState, useRef } from "react";
import http from "../../http";
import Filter from "./Filter";
import FilterBtn from "../../Components/FilterBtn";
import { useSelector, useDispatch } from "react-redux";
import { loaderSection } from "../../App/Features/Loader/TableSlice";
import TableLoader from "../../Components/TableLoader";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import StatusUpdate from "./StatusUpdate";
import ViewOrder from "./ViewOrder";

export default function Index() {
  const showFilter = useSelector((state) => state.filter.value);
  const filterCounter = useSelector((state) => state.filter.value);
  const dispatch = useDispatch();
  const tabLoader = useSelector((state) => state.tableLoader.value);
  const [records, setRecord] = useState([]);
  const [rowId, setRowId] = useState([]);
  const [order,setOrder] = useState([]);
  // const [filters, setFilter] = useState([]);

  //for show list of data
  useEffect(() => {
    menuStockReport();
  }, []);

  const menuStockReport = async (filters = []) => {
    dispatch(loaderSection(true));
    await http
      .get("order", { params: filters })
      .then((res) => {
        setRecord(res.data.records ?? []);
        if (!res.data.status) toastMsg(res.data.msg, false);
        dispatch(loaderSection(false));
      })
      .catch((error) => {
        toastMsg(error, false);
        dispatch(loaderSection(false));
      });
  };

  // Function to receive value from child
  const handleFilter = (value) => {
    menuStockReport(value);
  };
  const openModal = (id) => {
    setRowId(id);
    let items = records.filter((record) => record.id == id);
    let [item] = items;
    setOrder(item);
  };

  const modifedRecords = (id, statusval) => {
    let list = records.map((record) =>
      record.id == id
        ? {
            ...record,
            status: statusval,
          }
        : record
    );
    setRecord(list ?? []);
  };

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Order History</h3>

                <div className="card-tools"><FilterBtn /></div>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                {showFilter && <Filter onValueChange={handleFilter} />}
                <table id="example1" className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order No</th>
                      <th>Customer</th>
                      <th>Total Amount</th>
                      <th>Sett Amount</th>
                      <th>Payment Mode</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!tabLoader ? (
                      records.length > 0 ? (
                        records.map((record, index) => (
                          <tr>
                            <td>{++index}</td>
                            <td>
                              {record.order_no}
                              <br />
                              <small className="tag-small">
                                {record?.type?.toUpperCase()}
                              </small>
                            </td>
                            <td>
                              {ucword(record.customer_name)}
                              <br />
                              <small>
                                <i class="fa-solid fa-phone text-primary"></i>
                              </small>
                              &nbsp;{record.mobile_no}
                            </td>
                            <td>{currency(record.amount)}</td>
                            <td>{currency(record.settlement_amount)}</td>
                            <td>
                              {record?.payment_mode?.toUpperCase()}
                              <br />
                              {record.payment_status}
                            </td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{record.remarks}</Tooltip>}
                              >
                                <span
                                  className="tag-small"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title=""
                                  data-original-title="success"
                                >
                                  {ucword(record.status)}
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td>{dFormat(record.created_at)}</td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                onClick={(e) => openModal(record.id)}
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </a>
                              <a
                                href="javascript:void(0);"
                                className="ml-1 text-info"
                                onClick={(e) => openModal(record.id)}
                                data-toggle="modal"
                                data-target="#viewOrder"
                              >
                                <i class="fa-regular fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            Not Found Any Records.
                          </td>
                        </tr>
                      )
                    ) : (
                      <TableLoader colspan="9" type="table" />
                    )}
                  </tbody>
                </table>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </div>

      <StatusUpdate id={rowId} modifedRecords={modifedRecords}></StatusUpdate>
      <ViewOrder order={order}></ViewOrder>
    </>
  );
}
