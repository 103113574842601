import React, { useState, useEffect } from "react";
import http from "../../http";

const Filter = ({ onValueChange }) => {
  const [input, setInput] = useState([]);

  useEffect(() => {
  }, []);

  const handleInput = (e) => {
    e.persist();
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const setFilter = async () => {
    onValueChange(input);
  };

  const clear = async () => {
    setInput([]);
    onValueChange([]);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <div className="form-group">
            <label>Customer Name</label>
           <input type="text" className="form-control form-control-sm" onInput={handleInput} name="customer_name" placeholder="Customer Name"></input>
          </div>
        </div>

        <div className="col-md-2">
          <div className="form-group">
            <label>Customer Mobile No</label>
           <input type="number" className="form-control form-control-sm" onInput={handleInput} name="customer_mobile_no" placeholder="Customer Mobile No"></input>
          </div>
        </div>
        <div className="col-md-2 mt-4">
          <button
            type="button"
            onClick={setFilter}
            className="btn btn-success btn-sm mr-2"
          >
            <i className="fas fa-search"></i>&nbsp;Search
          </button>
          <a href="javascript:void(0);" onClick={clear} className="btn btn-danger btn-sm">
            <i className="fas fa-eraser"></i>&nbsp;Clear
          </a>
        </div>
      </div>
    </>
  );
};

export default Filter;
