import axios from 'axios';

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  baseURL:"https://kharidary.online/ims_api/api/",
  mode: 'no-cors',
  headers: {
    Accept: 'application/json',
    ContentType: 'multipart/form-data'
  }
});

instance.interceptors.request.use(
  function (config) {
    const token =  JSON.parse(sessionStorage.getItem('userData'));
    if (token) {
      config.headers.Authorization = `Bearer ${token?.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
