import React from "react";
import Layout from "../Layout/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Page/Login";
import Dashboard from "../Page/Dashboard";
import NotFoundPage from "../Page/NotFoundPage";
import Auth from "../Middleware/Auth";
import MenuIndex from "../Page/Menu/Index";
import MenuStock from "../Page/MenuStock/Index";
import StockReport from "../Page/Report/StockReport";
import Order from "../Page/Order/Index";
import OrderHistory from "../Page/OrderHistory/Index";

const RouteFile = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Login />}></Route>
          <Route exact path="/dashboard" element={<Auth component={Dashboard} />} />
          <Route exact path="/menu" element={<Auth component={MenuIndex} />} />
          <Route exact path="/menu-stock" element={<Auth component={MenuStock} />} />
          <Route exact path="/stock-report" element={<Auth component={StockReport} />} />
          <Route exact path="/order" element={<Auth component={Order} />} />
          <Route exact path="/order-history" element={<Auth component={OrderHistory} />} />
          <Route element={NotFoundPage} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default RouteFile;
