import React from "react";

const ViewOrder = (order) => {

  return (
    <>
      <div
        className="modal fade"
        id="viewOrder"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                View Order
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-1">
              <div className="row">
                <div className="col-md-6">
                  <h6>Order No:&nbsp;{order.order.order_no}</h6>
                  
                </div>
                <div className="col-md-6">
                  <h6>Order Amount:&nbsp;{order.order.amount}</h6>
                  
                </div>
                <div className="mt-2 col-md-12">
                  <table className="table table-sm">
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Amount</th>
                    </tr>

                    {order.order.items &&
                      order.order.items.map((record, index) => (
                        <tr>
                          <td>{++index}</td>
                          <td><img
                              className="imgTable"
                              src={record.image ?? Img()}
                            /></td>
                          <td>{record.menu_item}</td>
                          <td>{currency(record.price)}</td>
                          <td>{record.quantity}</td>
                          <td>{currency(record.quantity * record.price)}</td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrder;
