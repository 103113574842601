import React from "react";

const TableLoader = (props) => {
  return (
    <>
      {props.type =="table" ? (
        <tr>
          <td colSpan={props.colspan} className="text-center">
            <img src="/spinner.gif" alt="Loader" height="40" width="40" />
          </td>
        </tr>
      ) : (
        <div className="text-center">
          <img src="/spinner.gif" alt="Loader" height="40" width="40" />
        </div>
      )}
    </>
  );
};

export default TableLoader;
