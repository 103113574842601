import React, { useState, useEffect } from "react";

import http from "../../http";

const Index = () => {
  const [input, setInput] = useState([]);
  const [menus, setMenus] = useState([]);
  const [items, setItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [menuId, setMenuId] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    menuList();
    menuItemList();
    $(".select2")
      .select2({
        placeholder: "Select an item.",
      })
      .on("change", function () {
        menuItem($(this).val());
      });
  }, []);

  // const handleInput = (e) => {
  //   e.persist();
  //   const { name, value } = e.target;
  //   setInput((prevInput) => ({
  //     ...prevInput,
  //     [name]: value,
  //     assign_menu_id:
  //       name === "menu_item_id"
  //         ? getItemById(value).id
  //         : prevInput.assign_menu_id,
  //     price:
  //       name === "menu_item_id" ? getItemById(value).price : prevInput.price,
  //   }));
  // };

  const handleInput = (e) => {
    e.persist();
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const menuList = async () => {
    await http
      .get("assign-menu-list")
      .then((res) => {
        setMenus(res.data.records ?? []);
        let menuItem = res.data.records
          .filter((e) => {
            return e.id == res.data.records[0].id;
          })
          .map((e) => e.sub_menu)
          .flat();
        setMenuItems(menuItem);
        setMenuId(res.data.records[0].id);
        if (!res.data.status) toastMsg(res.data.msg, false);
      })
      .catch((error) => {
        toastMsg(error, false);
      });
  };

  // const subMenuList = async (value) => {
  //   let payload = { menu_id: value };
  //   await http
  //     .get("sub-menu-list", { params: payload })
  //     .then((res) => {
  //       setItems(res.data.records ?? []);
  //       if (!res.data.status) toastMsg(res.data.msg, false);
  //     })
  //     .catch((error) => {
  //       toastMsg(error, false);
  //     });
  // };

  const menuItemList = async (value) => {
    let payload = { sub_menu_id: value };
    await http
      .get("menu-item-list", { params: payload })
      .then((res) => {
        setItems(res.data.records ?? []);
        if (!res.data.status) toastMsg(res.data.msg, false);
      })
      .catch((error) => {
        toastMsg(error, false);
      });
  };

  // const add = () => {
  //   setItems([...items, input]);
  //   setInput({ menu_item_id: "", quantity: "", assign_menu_id: "", price: 0 });
  // };

  // const remove = (index) => {
  //   const updatedItems = items.filter((item, i) => i !== index);
  //   setItems(updatedItems); // Update state with the filtered array
  // };

  // const getItemById = (id) => {
  //   return menuItems.find((item) => item.menu_item_id == id);
  // };

  const saveOrder = () => {
    if (input.mobile_no == "" || input.mobile_no == null) {
      setError({ mobile_no: "Mobile no field is required." });
      return false;
    }

    let items = cart.map((e) => {
      return {
        assign_menu_id: e.menu_id,
        menu_item_id: e.item_id,
        quantity: e.qty,
        price: e.price,
      };
    });
    let payload = {
      amount: totalPrice,
      payment_mode: input.payment_mode,
      settle_amount: input.settl_amount,
      payment_status: "success",
      status: "created",
      customer_name: input.name,
      customer_mobile_no: input.mobile_no,
      type:'store',
      remarks:input.remarks,
      assign_menu: items,
    };
    http
      .post("order", payload)
      .then((res) => {
        if (res.data.validation) {
          toastValidationMsg(res.data.validation);
          return false;
        }
        toastMsg(res.data.msg, res.data.status);
        setCart([]);
      })
      .catch((error) => {
        toastMsg(error, false);
      });
  };

  // const totalPrice = items.reduce((acc, val) => {
  //   const item = getItemById(val.menu_item_id);
  //   let price = parseFloat(item.price);
  //   return acc + price * val.quantity;
  // }, 0);

  const click = (menu_id) => {
    var menuItem = menus
      .filter((e) => {
        return e.id == menu_id;
      })
      .map((e) => e.sub_menu)
      .flat();
    setMenuItems(menuItem);
    setMenuId(menu_id);
  };

  const menuItem = (item_id) => {
    if (cart.find((val) => val.item_id == item_id)) {
      toastMsg("This Item is alreday added");
      return true;
    }

    let items = menuItems
      .flatMap((value) => value.menu_items)
      .filter((item) => item.id === item_id);
    let [item] = items;
    setCart([
      ...cart,
      {
        menu_id: item?.assign_menu_id,
        item_id: item.id,
        item_name: item.name,
        qty: 1,
        price: item.price,
        stock: item.stock,
        image: item.image,
      },
    ]);
  };

  const addCart = (id, qty) => {
    let items = cart.map((e) => (e.item_id == id ? { ...e, qty: qty + 1 } : e));
    setCart(items);
  };

  const minusCart = (id, qty) => {
    let items = cart.map((e) => (e.item_id == id ? { ...e, qty: qty - 1 } : e));
    setCart(items);
  };

  const removeCart = (id) => {
    let items = cart.filter((e) => e.item_id !== id);
    setCart(items);
  };

  const totalPrice = cart.reduce(
    (acc, val) => acc + val.qty * parseFloat(val.price),
    0
  );

  const search = (id) => {
    console.log("menuItems", menuItems);
    console.log("id", id);
    let items = menuItems
      .flatMap((value) => value.menu_items)
      .filter((item) => item.id == id);
    console.log("itemValue", items);
  };
  // const saveOrder = (e) => {};
  console.log("error", error);
  // console.log("menuID", menuId);
  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Order</h3> */}
              <div className="card-tools float-left">
                <select
                  className="form-control form-control-sm w-100 select2"
                  style={{ width: "180px" }}
                >
                  <option></option>
                  {items &&
                    items.map((list, index) => (
                      <option value={list.menu_item_id}>
                        {list.menu_item}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body h-100 p-2">
              <div className="row">
                <div className="col-md-2">
                  <div className="card card-widget widget-user-2">
                    <div className="card-footer p-0">
                      <ul className="nav flex-column">
                        {menus &&
                          menus.map((list, index) => (
                            <li className="nav-item" key={index}>
                              <a
                                href="javascript:void(0)"
                                className={`nav-link ${
                                  menuId == list.id ? "activeMenu" : ""
                                }`}
                                onClick={(e) => click(list.id)}
                              >
                                {list.menu}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {menuItems.length > 0 && (
                  <div className="col-md-6">
                    <div className="card p-2">
                      {menuItems.map((list, index) => (
                        <div key={index} className="">
                          <h6 className="deco">{list.name}</h6>
                          <div className="row">
                            {list.menu_items &&
                              list.menu_items.map((item, idx) => (
                                <div key={idx} className="col-md-4">
                                  <a
                                    href="javascript:void(0);"
                                    onClick={(e) => menuItem(item.id)}
                                    className={
                                      cart.find((val) => val.item_id == item.id)
                                        ? "disabledLink"
                                        : ""
                                    }
                                  >
                                    <div
                                      className="info-box box-border"
                                      style={{ "min-height": "0px" }}
                                    >
                                      <div className="info-box-content">
                                        <span className="info-box-text text-dark">
                                          {item.name}
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {cart.length > 0 && (
                  <div className="col-md-4 card">
                    <table className="table table-sm">
                      <tr>
                        {/* <th>Image</th> */}
                        <th>Items</th>
                        <th style={{ width: "60px" }}>Qty</th>
                        <th style={{ width: "50px" }}>Price</th>
                      </tr>
                      {cart &&
                        cart.map((val, inx) => (
                          <tr>
                            {/* <td>
                            <img
                              className="imgTable"
                              src={val.image ?? Img()}
                            />
                          </td> */}

                            <td>
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => removeCart(val.item_id)}
                                className="text-danger"
                              >
                                &nbsp;
                                <i class="fas fa-times-circle"></i>
                              </a>
                              &nbsp;{val.item_name}
                            </td>
                            <td>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      addCart(val.item_id, val.qty)
                                    }
                                    className="btn btn-outline-secondary btn-sm"
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={val.qty}
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    disabled={val.qty <= 1 ? true : false}
                                    onClick={(e) =>
                                      minusCart(val.item_id, val.qty)
                                    }
                                    className="btn btn-outline-secondary btn-sm"
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td>{currency(val.qty * val.price)}</td>
                          </tr>
                        ))}

                      {totalPrice ? (
                        <>
                          <tr>
                            <th colSpan={2} className="text-right">
                              Total
                            </th>
                            <td colSpan={2}>{currency(totalPrice)}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "70px" }}
                              className="form-group"
                            >
                              <div class="custom-control custom-radio">
                                <input
                                  className="custom-control-input payment_mode"
                                  type="radio"
                                  id="customRadio1"
                                  value="cash"
                                  onClick={handleInput}
                                  name="payment_mode"
                                />
                                <label
                                  for="customRadio1"
                                  class="custom-control-label pt-1"
                                >
                                  Cash
                                </label>
                              </div>
                              {input.payment_mode == "cash" && (
                                <input
                                  type="number"
                                  id="settl_amount"
                                  name="settl_amount"
                                  onInput={handleInput}
                                  className="form-control form-control-sm w-100 "
                                  placeholder="Settl Amount"
                                ></input>
                              )}
                            </td>
                            <td>
                              <div class="custom-control custom-radio">
                                <input
                                  className="custom-control-input payment_mode"
                                  type="radio"
                                  id="customRadio2"
                                  value="online"
                                  onClick={handleInput}
                                  name="payment_mode"
                                />
                                <label
                                  for="customRadio2"
                                  class="custom-control-label pt-1"
                                >
                                  Online
                                </label>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <input
                                type="text"
                                onInput={handleInput}
                                className="form-control form-control-sm"
                                name="name"
                                placeholder="Enter Customer Name"
                              ></input>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <input
                                type="number"
                                onInput={handleInput}
                                className="form-control form-control-sm"
                                name="mobile_no"
                                placeholder="Enter Mobile No"
                              ></input>
                              <span className="text-danger">
                                {error?.mobile_no}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <textarea
                                onInput={handleInput}
                                className="form-control form-control-sm"
                                name="remarks"
                                placeholder="Enter Remarks"
                              ></textarea>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-center">
                              <button
                                type="button"
                                onClick={saveOrder}
                                className="btn btn-sm btn-success"
                              >
                                Save & Print
                              </button>
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
