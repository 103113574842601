import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setToken } from "../token";
import ValidationMsg from "../Components/ValidationMsg";

const Login = () => {
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });
  const [validationMsg, setValidationMsg] = useState({});
  const [errorMsg, setMsg] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("userData")) {
      navigate("/dashboard");
    }
  }, []);

  const handleInput = (e) => {
    e.persist();
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  var url = "https://kharidary.online/ims_api/api/login";
  // var url = "http://127.0.0.1:8000/api/login";
  async function login(e) {
    e.preventDefault();
    setLoader(true);
    let payload = { username: loginInput.email, password: loginInput.password,type:'retail_store' };
    let result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    result = await result.json();
    setLoader(false);
    if (result.status) {
      setToken(result.access_token);
      sessionStorage.setItem(
        "userData",
        JSON.stringify({
          login: true,
          user: result.user,
          token: result.authorisation.token,
        })
      );

      var userData = JSON.parse(sessionStorage.getItem("userData"));
      if (userData && userData.token) {
        navigate("/dashboard");
      }
    } else {
      setValidationMsg({});
      if (result.validation) {
        setValidationMsg(result.validation);
      }
      setMsg({
        msg: result.msg,
      });
    }
  }
  return (
    <>
      <div className="login-box">
        <div className="card ">
          {/* <div className="card-header text-center">
            <a href="" className="h3">
              <b>Parmukh</b>GST
              <img src="/parmukh.png" alt="Parmukh Logo" />
            </a>
          </div> */}
          <div className="card-body">
            <div className="h3 text-center">
              <img src="/parmukh.png" alt="Parmukh Logo" />
            </div>
            <p className="login-box-msg">Sign in to start your session</p>
            {errorMsg.msg ? (
              <p className="login-box-msg text-danger">{errorMsg.msg}</p>
            ) : (
              ""
            )}
            <form onSubmit={login}>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  name="email"
                  onChange={handleInput}
                  value={loginInput.email}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div>
                <ValidationMsg msg={validationMsg.username} />
              </div>
              <div className="input-group mt-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  onChange={handleInput}
                  value={loginInput.password}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div>
                <ValidationMsg msg={validationMsg.password} />
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" className="mr-1" />
                    <span for="remember">Remember Me</span>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    disabled={(loader && true) || false}
                    type="submit"
                    className="btn btn-sm btn-warning sign-in w-100"
                  >
                    {(loader && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )) ||
                      "Sign In"}
                  </button>
                  {/* <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button> */}
                </div>
              </div>
            </form>

            <p className="mb-1">
              <Link to="dashboard" className="text-success">
                I forgot my password
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
